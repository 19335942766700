import React from 'react'
import styles from './style.module.css'

const MiniLoading = () => {
  return (
    <div className={styles.ldsRing}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default MiniLoading
