import { useState, useEffect, useContext, useRef } from 'react'
import styles from './style.module.css'
import { File } from '../icons'
import MainButton from '../main-button'
import StoreContext from '../../context/store'
import Inside from './inside'
import { errorNotify } from '../../helpers/notify'

const Select = ({ className, ...props }) => {
  const [dragActive, setDragActive] = useState(false) // drag state
  const [selectedFiles, setSelectedFiles] = useState([]) // selected file
  const [previews, setPreviews] = useState({}) // preview image
  const [errorsImage, setErrorsImage] = useState({}) // errors image

  const inputRef = useRef(null)

  const { setGoingIpfsFiles } = useContext(StoreContext)

  useEffect(() => {
    if (!selectedFiles) {
      resetStates()
      console.log("goingIpfsFiles:",goingIpfsFiles)
      return
    }

    const MAX_FILE_SIZE = 10240

    for (let i = 0; i < selectedFiles.length; i++) {
      let fileSizeKiloBytes = selectedFiles[i].size / 1024

      /*
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        errorNotify('File size is greater than maximum limit!')
        return
      }
      */
    }

    const objectUrlFirst = selectedFiles[0]
      ? URL.createObjectURL(selectedFiles[0])
      : null
    const objectUrlSecond = selectedFiles[1]
      ? URL.createObjectURL(selectedFiles[1])
      : null
    const objectUrlThird = selectedFiles[2]
      ? URL.createObjectURL(selectedFiles[2])
      : null
    const objectUrlFourth = selectedFiles[3]
      ? URL.createObjectURL(selectedFiles[3])
      : null

    setPreviews({
      ...previews,
      first: objectUrlFirst,
      second: objectUrlSecond,
      third: objectUrlThird,
      fourth: objectUrlFourth
    })

    setGoingIpfsFiles(selectedFiles)

    return () => {
      URL.revokeObjectURL(previews.first)
      URL.revokeObjectURL(previews.second)
      URL.revokeObjectURL(previews.third)
      URL.revokeObjectURL(previews.fourth)
    }
  }, [selectedFiles])

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files)
    }
  }

  const handleChange = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files)
    }
  }

  const handleFiles = (files) => {
    setSelectedFiles(files)
  }

  const onButtonClick = () => {
    inputRef.current.click()
  }

  const deletePreview = (e) => {
    e.preventDefault()
    resetStates()
  }

  const catchImageError = (file, fileNumber) => {
    if (!file.name.match(/.(jpg|jpeg|png|gif|svg|ico)$/i)) {
      switch (fileNumber) {
        case 1:
          setErrorsImage({
            ...errorsImage,
            first: true
          })
          break
        case 2:
          setErrorsImage({
            ...errorsImage,
            second: true
          })
          break
        case 3:
          setErrorsImage({
            ...errorsImage,
            third: true
          })
          break
        case 4:
          setErrorsImage({
            ...errorsImage,
            fourth: true
          })
          break
        default:
          break
      }
    }
  }

  const resetStates = () => {
    setPreviews({
      ...previews,
      first: undefined,
      second: undefined,
      third: undefined,
      fourth: undefined
    })
    setSelectedFiles([])
    setGoingIpfsFiles([])
  }

  return (
    <div className={[styles.selectContainer, className].join(' ')} {...props}>
      <div className={[styles.box, styles.outerFourth].join(' ')}>
        {previews?.fourth && (
          <Inside
            file={selectedFiles[3]}
            source={previews?.fourth}
            number={4}
            isError={errorsImage.fourth}
            catchImageError={catchImageError}
          />
        )}
        <div className={[styles.box, styles.outerThird].join(' ')}>
          {previews?.third && (
            <Inside
              file={selectedFiles[2]}
              source={previews?.third}
              number={3}
              isError={errorsImage.third}
              catchImageError={catchImageError}
            />
          )}
          <div className={[styles.box, styles.outerSecond].join(' ')}>
            {previews?.second && (
              <Inside
                file={selectedFiles[1]}
                source={previews?.second}
                number={2}
                isError={errorsImage.second}
                catchImageError={catchImageError}
              />
            )}
            <div className={[styles.box, styles.outerFirst].join(' ')}>
              <form
                className="relative text-center h-full w-full"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={inputRef}
                  type="file"
                  className="hidden"
                  id="input-file-upload"
                  multiple={true}
                  onChange={handleChange}
                />
                <label
                  htmlFor="input-file-upload"
                  className={dragActive ? 'bg-white' : 'bg-gray-200'}
                >
                  {previews?.first ? (
                    <Inside
                      file={selectedFiles[0]}
                      source={previews?.first}
                      number={1}
                      isError={errorsImage.first}
                      catchImageError={catchImageError}
                      deletePreview={deletePreview}
                    />
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full rounded-4xl">
                      <File width="108" height="108" />
                      <p className="mt-4">Drag and drop anything here</p>
                      <MainButton onClick={onButtonClick} className="mt-6 mb-2">
                        Choose Anything
                      </MainButton>
                      <p className="text-sm">Your data is secure</p>
                      <p className="text-sm text-main-dark">Max Size: 10MB</p>
                    </div>
                  )}
                </label>
                {dragActive && (
                  <div
                    className="absolute w-full h-full bg-transparent rounded-4xl border-4 border-dashed border-white top-0 bottom-0 right-0 left-0"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Select
