import { useContext } from 'react'
import styles from '../styles/CreateNft.module.css'
import Head from 'next/head'
import Select from '../components/select'
import MainButton from '../components/main-button'
import StoreContext from '../context/store'
import Typewriter from 'typewriter-effect'
import MiniLoading from '../components/loading/miniLoading'
import { warningNotify } from '../helpers/notify'
import { chainHex } from '../config'

const CreateNft = () => {
  const {
    sendFileToIPFS,
    sendFileToContract,
    isLoading,
    isCorrectNetwork,
    chainId,
    connected,
    switchNetwork,
    networkId,
    sendBase
  } = useContext(StoreContext)

  const mainButtonText = () => {
    if (chainId) {
      if (!isCorrectNetwork) {
        console.log('Switch Network to BSC chainId=true isCorrectNetwork=false')
        return 'Switch Network'
      } else {
        console.log('Deploy chainId=true isCorrectNetwork=true')
        return 'Deploy'
      }
    } else {
      console.log('Deploy chainId=false')
      return 'Deploy'
    }
  }

  const deployNfts = () => {
    if (connected) {
      if (isCorrectNetwork) {
        sendFileToIPFS()
        // sendFileToContract()
        // sendBase()
      } else {
        switchNetwork(chainHex[networkId])
      }
    } else {
      warningNotify('Please connect a wallet before deploy!')
    }
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Create NFT</title>
        <meta name="description" content="Turn everything into NFTs" />
      </Head>
      <div className={styles.container}>
        <Select className={styles.selectSide} />
        <div className={styles.textSide}>
          <p>Turn</p>
          <Typewriter
            options={{
              strings: ['.JPG', '.PNG', '.MP3', '.MP4', '.PDF'],
              autoStart: true,
              loop: true,
              deleteSpeed: 4
            }}
          />
          <p> into NFTs</p>
          <MainButton
            className={styles.deployButton}
            onClick={() => deployNfts()}
            disabled={connected ? false : true}
          >
            {isLoading ? <MiniLoading /> : mainButtonText()}
          </MainButton>
        </div>
      </div>
    </>
  )
}

export default CreateNft
