import styles from './style.module.css'

const MainButton = ({ children, className, ...props }) => {
  return (
    <button className={[styles.container, className].join(' ')} {...props}>
      {children}
    </button>
  )
}

export default MainButton
