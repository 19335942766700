/* eslint-disable @next/next/no-img-element */
import { useContext } from 'react'
import { X } from '../icons'
import StoreContext from '../../context/store'
import Image from 'next/image'

const Inside = ({
  file,
  source,
  number,
  isError,
  catchImageError,
  deletePreview
}) => {
  const { nftName, setNftName, nftDescription, setNftDescription } =
    useContext(StoreContext)

  return (
    <div>
      {isError ? (
        <div className="text-center gap-1 text-sm">
          <p>{file?.name?.slice(0, 10)}..</p>
          <p>{file?.type?.slice(0, 14)}..</p>
        </div>
      ) : /* <img
          src={source}
          alt="Preview Image"
          className="rounded-4xl"
          onError={catchImageError(file, number)}
        /> */

      file.type == 'audio/mpeg' ? (
        <Image
          alt="Preview Image"
          src="/mp3.png"
          className="center rounded-4xl"
          objectFit="cover"
          layout="fill"
        />
      ) : file.type == 'video/mp4' ? (
        <Image
          alt="Preview Image"
          src="/mp4.png"
          className="center rounded-4xl"
          layout='fill'
          objectFit="cover"
        />
      ) : file.type == 'application/pdf' ? (
        <Image
          alt="Preview Image"
          src="/pdf.png"
          className="center rounded-4xl"
          objectFit="cover"
          layout="fill"
        />
      ) : (
        <Image
          alt="Preview Image"
          src={source}
          layout="fill"
          objectFit="cover"
          className="rounded-4xl"
          onError={catchImageError(file, number)}
        />
      )}
      {number === 1 && (
        <>
          <button className="absolute right-4 top-4" onClick={deletePreview}>
            <X width="24" height="24" color="#000" />
          </button>
          <div className="flex w-full justify-evenly absolute bottom-2">
            <input
              type="text"
              onChange={(e) => setNftName(e.target.value)}
              placeholder="Nft Name"
              required
              className="px-2 py-2 rounded-md text-sm text-main-dark border border-blue-500"
              value={nftName}
            />
            <input
              type="text"
              onChange={(e) => setNftDescription(e.target.value)}
              placeholder="Nft Description"
              required
              className="px-2 py-2 rounded-md text-sm text-main-dark border border-blue-500"
              value={nftDescription}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Inside
